import React, { useEffect, useState } from 'react';
import { insertentaineur } from '../../Service/Entraineur/EntraineurApi';
import { useNavigate } from 'react-router-dom';
import { getcategories } from '../../utils/Categories';
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi'
import { uploadimage } from '../../Service/UploadImage/UploadImage';
import { uploadPDF } from '../../Service/ImportPdfs/ImportPdfsApi';
import { getAllEntraineurs } from '../../Service/Entraineur/EntraineurApi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getclub, updatesteps } from '../../Service/Inscription/ClubInscription/clubinscription';
import { updatenumlicences } from '../../Service/Federation/FederationApi';
export default function FormulaireEntraineur() {
  const navigate = useNavigate();
  const [specialite, setspecialite] = useState([])

  const [formData, setFormData] = useState([
    {
      nom: '',
      prenom: '',
      idClub: localStorage.getItem('idunique'),
      specialites: [],
      datenaissance: '',
      cin: '',
      photocin: '',
      lieunaissance: '',
      diplome: '',
      image: null,
      categories_age: [],
      numtele: '',
      nationalite: '',
      Trampoline_CA: '',
      Parkour_CA: '',
      GR_CA: '',
      GAF_CA: '',
      GAM_CA: '',
      AeroGym_CA: '',
      nbrlicence: 0,
      contract: '',
      profession: '',
      societe: '',
      adressesociete: '',
      codeunique: '',
      typecin: '',
      sexe:''
    },
  ]);
  const [club, setclub] = useState()
  useEffect(() => {
    const fetchClubs = async () => {
      const clubResp = await getclub();
      if (clubResp.status === 200) {
        setclub(clubResp.data.data[0]);
      } else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")
      }

    }
    fetchClubs();
  }, []);

  const ajouterChamp = () => {
    setFormData([
      ...formData,
      {
        nom: '',
        prenom: '',
        idClub: localStorage.getItem('idunique'),
        specialites: [],
        datenaissance: '',
        cin: '',
        photocin: '',
        lieunaissance: '',
        diplome: '',
        image: null,
        categories_age: [],
        numtele: '',
        nationalite: '',
        Trampoline_CA: '',
        Parkour_CA: '',
        GR_CA: '',
        GAF_CA: '',
        GAM_CA: '',
        AeroGym_CA: '',
        nbrlicence: 0,
        contract: '',
        profession: '',
        societe: '',
        adressesociete: '',
        codeunique: '',
        typecin: '',
        sexe:''


      },
    ]);
  };


  useEffect(() => {
    const fetchsDiscipline = async () => {
      try {
        const respdiscipline = await Getdiscipline();

        setspecialite(respdiscipline.data.data)
      } catch (error) {
        toast.error('حدث خطأ أثناء الحصول على البيانات');
      }
    }
    fetchsDiscipline();
  }, []);

  const handleChangeSpecialite = async (personIndex, specialiteValue, indexitem, check) => {
    const newData = [...formData];
    if (check) {

      if (newData[personIndex]) {

        newData[personIndex].specialites = [...newData[personIndex].specialites, specialiteValue];

        if (newData[personIndex].datenaissance && specialiteValue) {


          newData[personIndex]["categories_age"][indexitem] = '';

        }

        setFormData(newData);
      }
    }
    else {

      newData[personIndex].specialites = newData[personIndex].specialites.filter(
        (item) => item !== specialiteValue
      );
      newData[personIndex]["categories_age"][indexitem] = "";

      setFormData(newData);
    }
  };

  const handleChange = async (e, index) => {
    const { name, value } = e.target;


    const newData = [...formData];

    if (name === 'image' || name === 'diplome' || name === 'photocin' || name === 'contract') {
      const file = e.target.files[0];
      newData[index][name] = file;
    } else {
      newData[index][name] = value;
    }

    setFormData(newData);
  };

  const supprimerChamp = (index) => {
    const newData = [...formData];
    newData.splice(index, 1); // Remove the element at the specified index
    setFormData(newData);
  };
  const updatestep = async () => {
    const data = { step: 3 };
    if (club.stepInscription < data.step) {


      const clubResp = await updatesteps(data);
      if (clubResp.status === 200) {
        console.log("stepupdated")
      }
    }
  }
  const verifspecialite = ()=>{
    for (let i = 0; i < formData.length; i++) {
      const personData = formData[i];
      if (personData.specialites.length === 0){
        return {sucess : false ,index : i}
      }

    }
    return {sucess : true ,index : -1}
  }
  const Enregistrer = async () => {
    try {
      const resverification =verifspecialite();
      if(resverification.sucess===false){
        toast.warning(`الرجاء اختيار التخصص للمدرب رقم${resverification.index+1}        `  )

      }else{
      for (let i = 0; i < formData.length; i++) {
        const personData = formData[i];

        if (personData.image && personData.nom && personData.prenom && personData.cin) {
        

            const respAll = await getAllEntraineurs();
            if (respAll.status === 200) {
              const existingAthlete = respAll.data.data.find((entraineur) => {
                return parseInt(personData.cin) === entraineur.cin;
              });

              if (existingAthlete) {
                alert(`هذا المدرب موجود بالفعل (CIN ${personData.cin} تم استخدامه سابقًا)`);
                return
              }
            }
            //upload image

            const imagedataframe = new FormData();
            imagedataframe.append('image', personData.image);
            const imageName = encodeURIComponent(`${localStorage.getItem('idunique')}_imageEntraieur_${personData.cin}`);
            const resp = await uploadimage(imageName, imagedataframe);
            if (resp.status === 200) {

              let fileExtension = personData.image.name.split('.').pop();
              personData.image = `/${imageName}.${fileExtension}`;

              //upload diplome fichier 
              const Dipmlomedataframe = new FormData();
              Dipmlomedataframe.append('pdf', personData.diplome);
              const fileName = encodeURIComponent(`${localStorage.getItem('idunique')}diplome_${personData.cin}`);
              const resPDF = await uploadPDF(fileName, Dipmlomedataframe);
              if (resPDF.status === 200) {
                const diplomefileExtension = personData.diplome.name.split('.').pop();
                personData.diplome = `pdf/${fileName}.${diplomefileExtension}`;
                //upload contract fichier 
                const Contractdataframe = new FormData();
                Contractdataframe.append('pdf', personData.contract);
                const fileNamecontract = encodeURIComponent(`${localStorage.getItem('idunique')}contract_${personData.cin}`);
                const resPDFcontract = await uploadPDF(fileNamecontract, Contractdataframe);
                if (resPDFcontract.status === 200) {
                   fileExtension = personData.contract.name.split('.').pop();

                  personData.contract = `pdf/${fileNamecontract}.${fileExtension}`;

                  //upload cin fichier 
                  const Cindataframe = new FormData();
                  Cindataframe.append('pdf', personData.photocin);
                  const fileNameCin = encodeURIComponent(`${localStorage.getItem('idunique')}cin_${personData.cin}`);
                  const resPDFcin = await uploadPDF(fileNameCin, Cindataframe);
                  if (resPDFcin.status === 200) {
                    fileExtension = personData.photocin.name.split('.').pop();

                    personData.photocin = `pdf/${fileNameCin}.${fileExtension}`;
                    const indexp = personData.index;
                    delete personData.index;
                    // Send formDataToSend to the backend
                    personData.specialites.forEach((element) => {
                      personData.nbrlicence++
                      personData[element] = `FTGYM_Licence-Entraineur-${element}_${personData.nom}_${localStorage.getItem('idunique')}_${personData.cin}`;

                    });
                    delete personData.categories_age;
                    delete personData.specialites;
                    const respInsert = await insertentaineur(personData);
                    if (respInsert.status === 201) {
                      supprimerChamp(indexp)
                     

                    }

                  }
                }
              }
            }
          
        }
      }
      toast.success('تم تسجيل المدربين بنجاح  ')
      updatestep()
      navigate('/pdf');
    }
    }
    catch (error) {
      toast.error('حدث خطأ أثناء حفظ البيانات', error);
    }
  };

  return (
    <div>
      <header>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

        <div className="image-container">
          <div className="info-container">
            <p>
              <strong>المدربين</strong>
            </p>

            <p>
              <strong> الموسم الرياضي {localStorage.getItem('saison')}</strong>
            </p>
          </div>
        </div>
      </header>
      {formData.map((person, index) => (
        <div key={index} className="person-form">
          <form enctype="multipart/form-data">
            <label>الاسم <span className="required"></span></label>
            <input
              required
              type="text"
              name="nom"
              value={person.nom}
              onChange={(e) => handleChange(e, index)}
            />
            <label>اللقب <span className="required"></span></label>
            <input
              required
              type="text"
              name="prenom"
              value={person.prenom}
              onChange={(e) => handleChange(e, index)}
            />
                <div className="row  mr-2 mb-2">
              <input className='col-2'
                type="radio"
                id={'sexe'}
                name={'sexe'}
                value={1}

                onChange={(e) => handleChange(e, index)}
              
              />
              <label className='col-3'>    ذكر </label>


              <input
                className='col-2'
                type="radio"
                id='sexe'
                name='sexe'
                value={0}

                onChange={(e) => handleChange(e, index)}

              />
              <label className='col-3'>     انثى </label>



            </div>
            <label>صورة شمسية <span className="required"></span></label>
            <input
              required
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={(e) => handleChange(e, index)}
            />
            <label>الشهائد العلمية <span className="required"></span></label>
            <input type="file" id="fileInput" 
              name="diplome"
              onChange={(e) => handleChange(e, index)}
              required
            />
            <label> عقد عمل <span className="required"></span></label>
            <input type="file" id="fileInput" 
              name="contract"
              onChange={(e) => handleChange(e, index)}
              required
            />
            <label >الجنسية<span className="required"></span></label>
            <input
              type="text"
              name="nationalite"
              value={person.nationalite}
              onChange={(e) => handleChange(e, index)}
            />
            
            <div className="row  mr-2 mb-2">
              <label className='col-3'> نوع الهوية : <span className="required"></span></label>
              <input className='col-1'
                type="radio"
                id={'typecin'}
                name={'typecin'}
                value={1}

                onChange={(e) => handleChange(e, index)}
              
              />
              <label className='col-3'>  بطاقة التعريف الوطنية </label>


              <input
                className='col-1'
                type="radio"
                id='typecin'
                name='typecin'
                value={0}

                onChange={(e) => handleChange(e, index)}

              />
              <label className='col-3'>    هوية أخرى </label>



            </div>
            <label>رقم بطاقة الهوية<span className="required"></span></label>
            <input
              type="number"
              name="cin"
              value={person.cin}
              onChange={(e) => handleChange(e, index)}
              required
            />
            <label>  بطاقة الهوية <span className="required"></span></label>
            <input
              required
              type="file"
              id="photocin"
              name="photocin"

              onChange={(e) => handleChange(e, index)}
            />
            <label>تاريخ الميلاد <span className="required"></span></label>
            <input
              type="date"
              name="datenaissance"
              value={person.datenaissance}
              required
              onChange={(e) => handleChange(e, index)}
            />
            <label>مكان الولادة <span className="required"></span></label>
            <input
              type="text"
              name="lieunaissance"
              value={person.lieunaissance}
              onChange={(e) => handleChange(e, index)}
            />
            <label> المهنة</label>
            <input
              type="text"
              name="profession"
              value={person.profession}
              onChange={(e) => handleChange(e, index)}
            />
            <label> المؤسسة المشغلة</label>
            <input
              type="text"
              name="societe"
              value={person.societe}
              onChange={(e) => handleChange(e, index)}
            />
            <label> العنوان  المهني</label>
            <input
              type="text"
              name="adressesociete"
              value={person.adressesociete}
              onChange={(e) => handleChange(e, index)}
            />
            <label>  المعرف الوحيد (الاساتذة)  </label>
            <input
              type="number"
              name="codeunique"
              value={person.codeunique}
              onChange={(e) => handleChange(e, index)}
            />
            <label>الاختصاصات <span className="required"></span></label>
            {specialite.map((item, indexitem) => (
              <div>
                <div className='row mb-2' key={indexitem}>
                  <input
                    className='col-1'
                    type="checkbox"
                    id={`parcour_${item.libellerFR}`}
                    name={`specialite[${index}]`}
                    value={item.libellerFR}
                    checked={formData[index].specialites.includes(item.libellerFR)}
                    onChange={(e) => handleChangeSpecialite(index, item.libellerFR, indexitem, e.target.checked)}
                  />
                  <img
                    className='icon col-2'
                    src={item.logo}
                    alt="Club Logo"
                    width="250px"
                    height="250px"
                  />
                  <label className='col-6' htmlFor={`parcour_${item.libellerFR}`}>{item.LibellerAR}</label>
                </div>

              </div>

            ))}




            <label>رقم الهاتف <span className="required"></span></label>
            <input
              type="number"
              name="numtele"
              value={person.numtele}
              onChange={(e) => handleChange(e, index)}
              required
            />
            <label>البريد الإلكتروني<span className="required"></span></label>
            <input
              type="email"
              name="email"
              value={person.email}
              required
              onChange={(e) => handleChange(e, index)}
            />

            <br />  <button onClick={() => supprimerChamp(index)}>مسح</button>
          </form>
        </div>
      ))}
      <div className=' buttonform'>
        <button onClick={ajouterChamp}>+   إضافة مدرب آخر </button>
        <button onClick={Enregistrer}>حفظ البيانات</button>


      </div>

    </div>
  );
} 
