import React, { useState } from 'react'
import { updatepassword } from '../../Service/Federation/FederationApi';
import { Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ModifMDP({setShowModel , showModel ,email}) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(false);

    const updatepasswordfunction = async (e) => {

        e.preventDefault();

        if (password === confirmPassword) {
            /// enregistre 
        
            const data = { password: password , email: email}
            const resp = await updatepassword(data)
            if (resp.status === 200) {
                toast.success('تم تحديث كلمة السر بنجاح                ')

                setShowModel(false)
            
            } else {

                toast.error('أعد المحاولة')
            }

        }
        else {

            setMessage(true);
        }
    }
  return (
    <div>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />
  
              <Modal show={showModel} onHide={()=>setShowModel(false)}>
                  <Modal.Header closeButton>
                      <Modal.Title> إعادة تعيين كلمة المرور</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <form >
                                <div>
                                    <label htmlFor="password">كلمة السر:</label>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value) }}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="confirmPassword">تأكيد كلمة السر</label>
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                    />
                                </div>
                                <div>
                                    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>{message ? "كلمة المرور غير مطابقة" : null}</span>

                                </div>
                                <button onClick={(e) => { updatepasswordfunction(e) }}>حفظ</button>
                            </form>         
                      </Modal.Body>
              </Modal>
          </div>
  )
}
