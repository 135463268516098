import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi'
import { setdevalideAthlete, setrevalideAthlete, setvalideAthelet } from '../../Service/Athlete/AthleteApi';
import { getatheletebyid } from '../../Service/Athlete/AthleteApi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';

import Certificat_pdf from './Certificat_pdf';
export default function TableauAtheletteById() {
  const navigate = useNavigate();
  const [licence, setlicence] = useState()
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [item, setSelectedItem] = useState('');
  const [newlisteObject, setnewlisteObject] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const [elementid,setelementid]=useState()
  const url = REACT_APP_API_URL

  const [filter, setFilter] = useState({
    name: '',



  });

  useEffect(() => {
    const fetchatheletes = async () => {
      const idclub = decodeURIComponent(id);

      const response = await getatheletebyid(idclub);
      if (response.status === 200) {
        const respdiscipline = await Getdiscipline();


        // Create a Set to store unique objects
        const uniqueObjects = new Set();

        response.data.data.forEach((element) => {
          respdiscipline.data.data.forEach((item) => {
            if (element[item.libellerFR] !== null) {
              let object = {
                specialites: item.LibellerAR,
                specialitesFR: item.libellerFR,

                categories_age: element[`${item.libellerFR}_CA`],
                numLicences: element[`${item.libellerFR}`],
                element, // Include the entire 'element' object
              };

              // Add the unique object to the Set
              uniqueObjects.add(JSON.stringify(object));
            }
          });
        });

        // Convert the Set back to an array
        const uniqueObjectArray = Array.from(uniqueObjects).map((jsonObject) =>
          JSON.parse(jsonObject)
        );

        setnewlisteObject(uniqueObjectArray);
      } else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")
      }
    };

    fetchatheletes();
  }, []);

  const openPDFInNewTab = (pdfFileName) => {
    const FN = encodeURIComponent(pdfFileName);
    const pdfUrl = `${url}${pdfFileName}`;

    const newTab = window.open(pdfUrl, '_blank');

    if (!newTab) {
      alert('Popup blocker prevented opening a new tab.');
    }
  };
  const setvalide = async (item) => {
    const resp = await setvalideAthelet(`${item.specialitesFR}_valide`, item.element.idunique);
    if (resp.status === 200) {
      window.location.reload()
    }
  }
  // Function to handle row click and show the popup with the selected image
  const handleRowClick = (item) => {
    setSelectedItem(item);
    setShowPopup(true);
  };
  const devalider = async (item) => {

    const resp = await setdevalideAthlete(`${item.specialitesFR}_valide`, item.element.idunique);
    if (resp.status === 200) {

      window.location.reload()
    }
  }

  const revalider = async (item) => {

    const resp = await setrevalideAthlete(`${item.specialitesFR}_valide`, item.element.idunique);
    if (resp.status === 200) {

      window.location.reload()
    }
  }
  const filtredathletes = newlisteObject.filter((item) => {
    const { name } = filter;
    return (
      item.element.nom.toLowerCase().includes(name.toLowerCase()) ||
      item.element.prenom.toLowerCase().includes(name.toLowerCase()) ||
      item.numLicences.toLowerCase().includes(name.toLowerCase())

    );
  });
  const download1 =async (item) => {
    const nom_club = localStorage.getItem('clubName');
  
    // Convertir l'image en base64
    alert(url+item.element.image)
    const imageBase64 = await urlToBase64(`${url+item.element.image}`);
  
    const copie = {
      numLicence: item.numLicences,
      nom: item.element.nom,
      prenom: item.element.prenom,
      image: imageBase64, // Utilisation de l'image en base64
      specFr: item.element[`${item.specialitesFR}_valide`],
      image1: item.element.image1,
      cin: item.element.cin,
      datenaissance: item.element.datenaissance,
      lieunaissance: item.element.lieunaissance,
      nationalite: item.element.nationalite,
      clubnom: nom_club,
      spec: item.specialites,
      cat_age: item.categories_age,
      QRdata: {
        id: item.id,
        nom: item.element.nom,
        prenom: item.element.prenom,
        id: item.element.id,
        idClub: item.element.idClub,
        libellerFR: item.specialitesFR,
        numlicence: item.numLicences,
        valide: item.element[`${item.specialitesFR}_valide`],
      }
    };
  
    setlicence(copie);
  
    const element = document.getElementById(`pdf-content${copie.numLicence}`);
    
    // Utiliser html2pdf pour générer le PDF
    html2pdf()
      .set({
        margin: 1,
        filename: `licence_${copie.nom}_${copie.prenom}.pdf`,
        image: { type: 'png', quality: 0.98 },
        html2canvas: { useCORS: true },
      })
      .from(element)
      .save();
  };
  const handlePrint = useReactToPrint({
    
    content: () => document.getElementById(licence?.numLicence),
  });
  
  const download =async (item) => {
    const nom_club = localStorage.getItem('clubName');
    const copie = {
      numLicence: item.numLicences,
      nom: item.element.nom,
      prenom: item.element.prenom,
      image: item.element.image, // Utilisation de l'image en base64
      specFr: item.element[`${item.specialitesFR}_valide`],
      image1: item.element.image1,
      cin: item.element.cin,
      datenaissance: item.element.datenaissance,
      lieunaissance: item.element.lieunaissance,
      nationalite: item.element.nationalite,
      clubnom: nom_club,
      spec: item.specialites,
      cat_age: item.categories_age,
      QRdata: {
        id: item.id,
        nom: item.element.nom,
        prenom: item.element.prenom,
        id: item.element.id,
        idClub: item.element.idClub,
        libellerFR: item.specialitesFR,
        numlicence: item.numLicences,
        valide: item.element[`${item.specialitesFR}_valide`],
      }
    };
  
    setlicence(copie);
  
  
    // Optional: Trigger printing immediately after downloading
    handlePrint(item.numLicences);
  
};
  
  // Fonction pour convertir une URL en base64
  async function urlToBase64(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  
  const downloadall = async () => {
    const nom_club = localStorage.getItem('clubName');
  
    // Define a function to handle each download sequentially
    const processDownload = async (item) => {
      const copie = {
        numLicence: item.numLicences,
        nom: item.element.nom,
        prenom: item.element.prenom,
        specFr: item.element[`${item.specialitesFR}_valide`],
        image1: item.element.image1,
        cin: item.element.cin,
        datenaissance: item.element.datenaissance,
        lieunaissance: item.element.lieunaissance,
        nationalite: item.element.nationalite,
        clubnom: nom_club,
        spec: item.specialites,
        cat_age: item.categories_age,
        QRdata: {
          id: item.id,
          nom: item.element.nom,
          prenom: item.element.prenom,
          elementId: item.element.id,
          idClub: item.element.idClub,
          numlicence: item.numLicences,
          valide: item.element[`${item.specialitesFR}_valide`],
          libellerFR: item.specialitesFR,
        }
      };
  
      // Assuming setlicence is an asynchronous function (e.g., setting state)
      await setlicence(copie);
  
      // Assuming handlePrint is an asynchronous function (e.g., printing)
      await handlePrint(item.numLicences);
    };
  
    // Use a loop to process each item sequentially
    for (let i = 0; i < filtredathletes.length; i++) {
      await processDownload(filtredathletes[i]);
    }
  };
  
  



  return (
    <>
      <div style={{ 'backgroundColor': 'white' }}>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />
        <span className='submit-btn ' onClick={() => downloadall()}   > تحميل  التراخيص
        </span>
        <header>
          <div className="image-container">

            <div className="info-container" >

              <p><strong >قائمة  الرياضيين     </strong></p>

              <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
              <p className='mr-5' > <strong>  العدد الجملي </strong> {filtredathletes.length}</p>

            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ "marginTop": "20px", 'width': '400px', 'marginLeft': '25px' }}>
                <input
                  type="text"
                  placeholder="بحث  "
                  value={filter.name}
                  onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                />
              </div>





            </div>
          </div>

        </header>

        <table>
          <thead>
            <tr>
              <th></th>
              <th className='center-text'>الاسم</th>
              <th className='center-text'>اللقب</th>
              <th className='center-text'>الجنس</th>

              <th className='center-text'>تاريخ الولادة</th>
              <th className='center-text'>الفئة العمرية</th>
              <th className='center-text'>الاختصاص</th>
              <th className='center-text'>رقم  الاجازة </th>


            </tr>
          </thead>
          <tbody>
            {filtredathletes && filtredathletes.length !== 0 && filtredathletes.map((person, i) => (
              <tr key={person.id}>
                <td>{i + 1}</td>
                <td className='center-text' onClick={() => handleRowClick(person)}>

                  {person.element.nom}

                </td>
                <td className='center-text'>

                  {person.element.prenom}

                </td>
                <td className='center-text'>{parseInt(person.element.sexe) === 0 ? 'انثى' : 'ذكر'}</td>



                <td className='center-text'>

                  {new Date(person.element.datenaissance).getFullYear()}-{(new Date(person.element.datenaissance).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-{new Date(person.element.datenaissance).getDate().toString().padStart(2, '0')}

                </td>
                <td className='center-text'>

                  {person.categories_age}

                </td>

                <td className='center-text'>

                  {person.specialites}

                </td>
                <td className='center-text'> {person.numLicences}</td>

                <td className='center-text' ><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => openPDFInNewTab(person.element.actnaissance)}>ActNaissance</a></td>
                <td className='center-text' ><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => openPDFInNewTab(person.element.PermissionParentale)}> PermissionParentale </a> </td>
                <td className='center-text' ><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => openPDFInNewTab(person.element.certificatmedicale)}> certificatmedicale </a> </td>

                <td className='center-text'>{person.element[`${person.specialitesFR}_valide`] === 0 ? (<div>
                  <button onClick={() => { setvalide(person) }}> valider </button>
                </div>) :
                  person.element[`${person.specialitesFR}_valide`] === 1 ? (
                    <>
                      <button onClick={() => { devalider(person); }}> إبطال صالحيته
                      </button>
                      <button onClick={() => download(person)}>تحميل</button></>


                  ) :
                    <button onClick={() => { revalider(person) }}> valider
                    </button>

                }</td>
              </tr>
            ))}

          </tbody>
        </table>
        {showPopup && (

          <div className="popupinfo">
            <div className="popup-contentinfo">
              <span className="close-popup" onClick={() => setShowPopup(false)}>
                &times;
              </span>
            
              <img src={`${url}uploads/${item.element.image}`} style={{ width: '200px', height: '201px' }} />
            </div>
          </div>
        )}

      </div>
      {licence &&
        <div  hidden  style={{ backgroundColor: "white" }}>
          <Certificat_pdf item={licence} />

        </div>
      }
    </>
  );

}
