import React, { useState,useEffect} from 'react';
import { GetRoles } from '../../Service/Directionclub/DirectionclubApi';
import {getdirectionbyid} from '../../Service/Directionclub/DirectionclubApi';
import { useParams ,useNavigate} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function TableauDirectionById() {
   const [persons, setPersons] = useState([]);
   const [roles , setroles]=useState([])
   const { id } = useParams();
   const { REACT_APP_API_URL } = process.env;
   const url = REACT_APP_API_URL ;
   const idclub = decodeURIComponent(id);
   const [filter, setFilter] = useState({
    name:'', 
  

  
  });
  useEffect(() => {
    const fetchRoles = async()=>{
     const roleResp = await GetRoles();
     if(roleResp.status === 200){
        setroles(roleResp.data.data); 
     }else{
      toast.error("نحن آسفون، يبدو أن هناك مشكلة")
    }
     
     }  
     fetchRoles();  
   }, []);
   useEffect(() => {
    const fetchdirection = async()=>{

     const response = await getdirectionbyid(idclub);
     
     if(response.status === 200){
       
        setPersons(response.data.data); 
     }else{
      toast.error("نحن آسفون، يبدو أن هناك مشكلة")
    }
     
     }  
     fetchdirection();  
   }, []);
 
   const openPDFInNewTab = (pdfFileName) => {
    const FN = encodeURIComponent(pdfFileName);
    const pdfUrl = `${url}uploadfile/downloadfederations/${FN}`;
  
    const newTab = window.open(pdfUrl, '_blank');
  
    if (!newTab) {
      toast.error("منع فتح نافذة جديدة")

    }
  };
  const filtredirection = persons.filter((item) => {
    const { name} = filter;
    return (
      item.nom.toLowerCase().includes(name.toLowerCase()) ||
      item.prenom.toLowerCase().includes(name.toLowerCase())

    );
  });

  return (
    
    <div style={{'backgroundColor':'white'}}>
 <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

    
         <header>
<div className="image-container">
 
<div className="info-container">

 <p><strong >قائمة  المسيرين     </strong></p>

 <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong>
 </p>
 <p className='mr-5' > <strong>  العدد الجملي </strong> {filtredirection.length}</p>

 </div>
 <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ "marginTop": "20px", 'width': '400px', 'marginLeft': '25px' }}>
              <input
                type="text"
                placeholder="بحث  "
                value={filter.name}
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
              />
            </div>
         
          



          </div>
</div>

</header>
         
      <table>
        <thead>
          <tr>
            <th></th>
            <th className='center-text'>الاسم</th>
            <th className='center-text'>اللقب</th>
            <th className='center-text'>الجنسية</th>
            <th className='center-text'>رقم بطاقة الهوية</th>
            <th className='center-text'>تاريخ الاصدار</th>
            <th className='center-text'> مكان الولادة</th>
            <th className='center-text'>تاريخ الولادة</th>
            <th className='center-text'>الوظيفة</th>
            <th className='center-text'>رقم الهاتف</th>
            <th className='center-text'>البريد الإلكتروني</th>
           
          </tr>
        </thead>
        <tbody>
        {filtredirection&& filtredirection.length!==0 && filtredirection.map((person,i) => (
            <tr key={person.id}>
              <td>{i+1}</td>
              <td className='center-text'>
            
                 { person.nom}
               
              </td>
              <td className='center-text'>
            
                 {person.prenom}
               
              </td>
              <td className='center-text'>
             
               {person.nationalite}   
               
              </td>
              <td className='center-text'>
              
                 { person.cin}
           
              </td>
              <td className='center-text'>
             
             {new Date(person.datecin).getFullYear()}-{(new Date(person.datecin).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-{new Date(person.datecin).getDate().toString().padStart(2, '0')}
               
              </td>
              <td className='center-text'>
                
                 {person.lieunaissance} 
               
              </td>
              <td className='center-text'>
                 {new Date(person.datenaissance).getFullYear()}-{(new Date(person.datenaissance).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}-{new Date(person.datenaissance).getDate().toString().padStart(2, '0')}
                    
              </td>
              <td className='center-text'>
             
                {person.role} 
              
              </td>
              <td className='center-text'>
              
                { person.numTelephone} 
            
              </td>
              <td className='center-text'>
              { person.email}
                 
            
              </td>

          
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
  
}
/*              <td ><a onClick={()=>{openPDFInNewTab(  `${ person.nom }_${ person.prenom }_${idclub}_directions`)}}> &#128065;</a></td>
*/