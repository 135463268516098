import React, { useEffect, useState } from 'react'
import { GetRoles,create} from '../../Service/Directionclub/DirectionclubApi';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadimage } from '../../Service/UploadImage/UploadImage';
import { getclub, getclubbyid, getinfoclub, updatesteps } from '../../Service/Inscription/ClubInscription/clubinscription';
export default function Formulairedirection() {
  const [club ,setclub]=useState()
  const navigate = useNavigate();

    const [roles , setroles]=useState([])
    const [formData, setFormData] = useState([
      { 
        index : null ,
        nom: "",
        prenom: "",
        role: "",
        numTelephone: "",
        email: "",
        idClub : localStorage.getItem('idunique'),
        cin:"",
        datecin:"",
        nationalite:"",
        datenaissance:"",
        lieunaissance:"",
        image:"",
        adresse:""
      },
    ]);
    useEffect(() => {
      const fetchClubs = async()=>{

      const clubResp = await getclub();
      if(clubResp.status === 200){
          setclub(clubResp.data.data[0]); 
      }else{
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")
      }
      
      }  
      fetchClubs();  
  }, []);
    useEffect(() => {
      const fetchRoles = async()=>{
       const roleResp = await GetRoles();
       if(roleResp.status === 200){
          setroles(roleResp.data.data); 
       }else{
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")
       }
       
       }  
       fetchRoles();  
     }, []);
    const ajouterChamp = () => {
      setFormData([...formData, { nom: "", prenom: "", role: "", numTelephone: "", email: "" ,idClub : localStorage.getItem('idunique'),cin:"", datecin:"",
      nationalite:"",
      datenaissance:"",
      lieunaissance:"",image:"",
      adresse:""}]);
    };

    const handleChange = (e, index,file) => {
      const { name, value } = e.target;
      const newData = [...formData];
     if (name === "image") {
        const file = e.target.files[0];
        newData[index][name] = file; 
      } else {
        newData[index][name] = value;
      }
      newData[index]['index'] = index;

      setFormData(newData);
    };
  
    const supprimerChamp = (index) => {
      const newData = [...formData];
      newData.splice(index, 1); // Supprime l'élément à l'index spécifié
      setFormData(newData);
    };
    const updatestep = async()=>{
      const data = {step:2};
      if(club.stepInscription < data.step){
        
    
    const clubResp = await updatesteps(data);
    if(clubResp.status === 200){
        console.log("stepupdated")
    }else{
      toast.error(" نحن آسفون، يبدو أن هناك مشكلة")

    }
  }
    } 
  /*
    const Enregistrer= async()=>{
    
      const respInsert = await insertDirection(formData) ;  
      console.log(respInsert)
      if(respInsert.status === 201) {
        //navigate('/FormulaireEntraineur')
        alert("yes")
      }
      else{
        alert("noo")
      }
    }*/
    const Enregistrer = async () => {
      try {
        for (let i = 0; i < formData.length; i++) {
          const personData = formData[i];
          if(personData.image && personData.nom && personData.prenom && personData.cin){
          const imagedataframe = new FormData();
          imagedataframe.append('image', personData.image);
          const imageName = encodeURIComponent(`${localStorage.getItem('idunique')}_imagedirection_${personData.numTelephone}`);
          const resp = await uploadimage(imageName,imagedataframe);
          if(resp.status === 200){
            const fileExtension = personData.image.name.split('.').pop();
             personData.image = `/${imageName}.${fileExtension}`;
             // test cin 
            const indexp =personData.index ;
            delete personData.index  ; 
          const respInsert = await create(personData);
    
          if (respInsert.status === 400) {
            toast.error(respInsert.data.message)
            return;
          }else if( respInsert.status === 201){
              supprimerChamp(indexp)
          }}
        }
      } 
      toast.success('تم تسجيل أعضاء الإدارة بنجاح      ')
      updatestep()
      navigate('/FormulaireEntraineur')

    }catch (error) {
        toast.error('حدث خطأ أثناء حفظ البيانات');
      }
    };
  
    return (
      <div>
     
      <div>
      <header>
    <div className="image-container">
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

    <div className="info-container">
        <p><strong>أعضاء الإدارة  </strong></p>
       
        <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
      </div>
      </div>
      </header>
      
        {formData.map((person, index) => (
          <div key={index} className="person-form">
            <form>
              <label>الاسم<span className="required"></span></label>
              <input
                type="text"
                name="nom"
                value={person.nom}
                required
                onChange={(e) => handleChange(e, index)}
              />
              <label>اللقب <span className="required"></span></label>
              <input
                type="text"
                name="prenom"
                value={person.prenom}
                onChange={(e) => handleChange(e, index)}
                required
              />
              <label>صورة شمسية <span className="required"></span></label>
              <input
            type="file"
            id="image" 
            name="image"
            accept="image/*"
            required
            onChange={(e) =>{ handleChange(e, index )}}
        
          />
          <br/>
            <label >الجنسية <span className="required"></span></label>
              <input
                type="text"
                required
                name="nationalite"
                value={person.nationalite}
                onChange={(e) => handleChange(e, index)}
              />
                <label  > العنوان <span className="required"></span></label>
              <input
                type="text"
                name="adresse"
                required
                value={person.adresse}
                onChange={(e) => handleChange(e, index)}
              />
                <label>رقم بطاقة الهوية <span className="required"></span></label>
              <input
                type="number"
                name="cin"
                required
                value={person.cin}
                onChange={(e) => handleChange(e, index)}
              />
              <label>تاريخ الاصدار<span className="required"></span></label>
                <input
                type="date"
                name="datecin"
                value={person.datecin}
                onChange={(e) => handleChange(e, index)}
              />
                <label> مكان الولادة<span className="required"></span></label>
                <input
                type="text"
                name="lieunaissance"
                value={person.lieunaissance}
                onChange={(e) => handleChange(e, index)}
              /> 

              <label>تاريخ الولادة <span className="required"></span></label>
                <input
                type="date"
                name="datenaissance"
                required
                value={person.datenaissance}
                onChange={(e) => handleChange(e, index)}
              />  

              <label>خطة <span className="required"></span></label>
                <select
                  name="role"
                  required
                  value={person.role}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option value=""> اختر </option>
                  {roles.map((role, roleIndex) => (
                    <option key={roleIndex} value={role.code}>
                      {role.role}
                    </option>
                  ))}
                </select>
  
              <label>رقم الهاتف<span className="required"></span></label>
              <input
                type="number"
                name="numTelephone"
                value={person.numTelephone}
                onChange={(e) => handleChange(e, index)}
              />
              <label>البريد الإلكتروني<span className="required"></span></label>
              <input
                type="email"
                name="email"
                value={person.email}
                onChange={(e) => handleChange(e, index)}
              />
              <button onClick={() => supprimerChamp(index)}>مسح</button>
              
            </form>
          </div>
        ))}
   <div className=' buttonform'>
      <button  onClick={ajouterChamp}>+     إضافة عضو إدارة </button>
              <button onClick={Enregistrer}>حفظ البيانات</button>
     
              </div>
      </div>
      </div>
    );
  }
  
      