import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Get pub Acceuil
 * @returns 
 */

export async function GetPubAcceuil() {

    try {
        
        const response = await axios.get(`${url}publication/getpubs`)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  /**
 * Get pub Acceuil
 * @returns 
 */

export async function deletepub(id) {

    try {
        
        const response = await axios.delete(`${url}publication/deletepub/${id}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   

/**
 * Get pub Acceuil
 * @returns 
 */

export async function insertpub(data) {
    try {
        console.log('data2',data)
        const response = await axios.post(`${url}publication/insertpub`, data);
        return response;
    } catch (error) {
        return error?.response;
    }
}

/**
 * Get pub Acceuil
 * @returns 
 */

export async function uploadPDFPublication(data) {
    try {
        const response = await axios.post(`${url}publication/uploadPDFPublication`, data);
        return response;
    } catch (error) {
        return error?.response;
    }
}/**
 * Get pub Acceuil
 * @returns 
 */

export async function updatePub(data) {
    try {
        const response = await axios.put(`${url}publication/updatepub`, data);
        return response;
    } catch (error) {
        return error?.response;
    }
}