import React, { useState, useEffect } from 'react';
import { getentraineurbyid, setdevalideEntraineur, setrevalideEntraineur } from '../../Service/Entraineur/EntraineurApi';
import { useNavigate, useParams } from 'react-router-dom';
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi';
import { setvalideEntraineur } from '../../Service/Entraineur/EntraineurApi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getnumlicencesEntraineur, updatenumlicencesentraineur } from '../../Service/Federation/FederationApi';
export default function TableauEntraineurById() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [persons, setPersons] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [item, setSelectedItem] = useState('');
  const [newlisteObject, setnewlisteObject] = useState([]);

  const { REACT_APP_API_URL } = process.env;
  const url = REACT_APP_API_URL

  const [filter, setFilter] = useState({
    name:'', 
  

  
  });
  useEffect(() => {
    const fetchentraineur = async () => {
      const idclub = decodeURIComponent(id);
      const EntraineurResp = await getentraineurbyid(idclub);
      if (EntraineurResp.status === 200) {
        const respdiscipline = await Getdiscipline();
        // Create a Set to store unique objects
        const uniqueObjects = new Set();

        EntraineurResp.data.data.forEach((element) => {
          respdiscipline.data.data.forEach((item) => {
            if (element[item.libellerFR] !== null) {
              let object = {
                specialites: item.LibellerAR,
                specialitesFR: item.libellerFR,
                categories_age: element[`${item.libellerFR}_CA`],
                numLicences: element[`${item.libellerFR}`],
                element, // Include the entire 'element' object
              };

              // Add the unique object to the Set
              uniqueObjects.add(JSON.stringify(object));
            }
          });
        });

        // Convert the Set back to an array
        const uniqueObjectArray = Array.from(uniqueObjects).map((jsonObject) =>
          JSON.parse(jsonObject)
        );

        setnewlisteObject(uniqueObjectArray);
      } else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")
      }

    }
    fetchentraineur();
  }, []);

  // Function to handle row click and show the popup with the selected image
  const handleRowClick = (item) => {
    setSelectedItem(item);
    setShowPopup(true);
  };


  const openPDFInNewTab = (pdfFileName) => {
    const FN = encodeURIComponent(pdfFileName);
    const pdfUrl = `${url}${pdfFileName}`;

    const newTab = window.open(pdfUrl, '_blank');

    if (!newTab) {
      alert('Popup blocker prevented opening a new tab.');
    }
  };

  const setvalide = async (item) => {

    const resp = await setvalideEntraineur(`${item.specialitesFR}_valide`, item.element.cin);
    if (resp.status === 200) {

      window.location.reload()
    }
  }

  const devalider = async (item) => {

    const resp = await setdevalideEntraineur(`${item.specialitesFR}_valide`, item.element.cin);
    if (resp.status === 200) {

      window.location.reload()
    }
  }

  const revalider = async (item) => {

    const resp = await setrevalideEntraineur(`${item.specialitesFR}_valide`, item.element.cin);
    if (resp.status === 200) {

      window.location.reload()
    }
  }
  const filtredentraineur = newlisteObject.filter((item) => {
    const { name} = filter;
    return (
      item.element.nom.toLowerCase().includes(name.toLowerCase()) ||
      item.element.prenom.toLowerCase().includes(name.toLowerCase())||
      item.numLicences.toLowerCase().includes(name.toLowerCase())

    );
  });
  return (
    <div style={{ 'backgroundColor': 'white' }}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

      <header>
        <div className="image-container">
          <div className="info-container">
            <p>
              <strong>قائمة المدربين </strong>
            </p>
            <p>
              <strong> الموسم الرياضي {localStorage.getItem('saison')}</strong>
            </p>
            <p className='mr-5' > <strong>  العدد الجملي </strong> {filtredentraineur.length}</p>

          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ "marginTop": "20px", 'width': '400px', 'marginLeft': '25px' }}>
              <input
                type="text"
                placeholder="بحث  "
                value={filter.name}
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
              />
            </div>
         
          



          </div>
        </div>
      </header>
      <table>
        <thead>
          <tr>
            <th></th>
            <th className='center-text'>الاسم</th>
            <th className='center-text'>اللقب</th>
            <th className='center-text'>الجنس</th>
            <th className='center-text'>تاريخ الولادة</th>
            <th className='center-text'>الهاتف</th>
            <th className='center-text'> البريد الإلكتروني</th>
            <th className='center-text'>  المهنة</th>
            <th className='center-text'>  المؤسسة المشغلة</th>
            <th className='center-text'>   العنوان المهني</th>
            <th className='center-text'>    المعرف الوحيد (الاساتذة)</th>
            <th className='center-text'>الفئة العمرية</th>
            <th className='center-text'>الاختصاص</th>
            <th className='center-text'>رقم  الاجازة </th>
          </tr>
        </thead>
        <tbody>

          {filtredentraineur && filtredentraineur.length !== 0 && filtredentraineur.map((person,i) => (
            <tr key={person.id}>
              <td>{i+1}</td>
              <td className='center-text' onClick={() => handleRowClick(person)}>

                {person.element.nom}

              </td>
              <td className='center-text'>

                {person.element.prenom}

              </td>

              <td className='center-text'>{parseInt(person.element.sexe) === 0 ? 'انثى' : 'ذكر'}</td>
              <td className='center-text'>

                {new Date(person.element.datenaissance).getFullYear()}-{(new Date(person.element.datenaissance).getMonth() + 1)
                  .toString()
                  .padStart(2, '0')}-{new Date(person.element.datenaissance).getDate().toString().padStart(2, '0')}

              </td>
              <td className='center-text'>{person.element.numtele}</td>
              <td className='center-text'>{person.element.email}</td>
              <td className='center-text'>{person.element.profession}</td>
              <td className='center-text'> {person.element.societe}</td>
              <td className='center-text'>{person.element.adressesociete}</td>
              <td className='center-text'>{person.element.codeunique}</td>

              <td className='center-text'>

                {person.categories_age}

              </td>

              <td className='center-text'>

                {person.specialites}

              </td>
              <td className='center-text'> {person.numLicences}</td>

              <td className='center-text' ><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => openPDFInNewTab(person.element.diplome)}>  الشهائد العلمية</a></td>
              <td className='center-text'  ><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => openPDFInNewTab(person.element.photocin)}>بطاقة تعريف </a> </td>
              <td className='center-text'  ><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => openPDFInNewTab(person.element.contract)}> عقد عمل </a> </td>

              <td className='center-text'>{person.element[`${person.specialitesFR}_valide`] === 0 ? (<div>
                <button onClick={() => { setvalide(person) }}> valider </button>
              </div>) :
                person.element[`${person.specialitesFR}_valide`] === 1 ? (
                  <button onClick={() => { devalider(person) }}> إبطال صالحيته
                  </button>
                ) :
                  <button onClick={() => { revalider(person) }}> valider
                  </button>

              }</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Popup to display the selected image */}
      {showPopup && (
        <div className="popupinfo">
          <div className="popup-contentinfo">
            <span className="close-popup" onClick={() => setShowPopup(false)}>
              &times;
            </span>
            <img src={`data:image/png;base64,${item.element.image1}`} style={{ width: '200px', height: '201px' }} />
          </div>
        </div>
      )}
    </div>
  );
}
