
import { getcategory } from '../Service/Athlete/AthleteApi';

export const getcategories = async( date ,spec)=>{
 

    try{
      const rep = await getcategory( date ,spec) ; 
      if (rep && rep.status === 200) {
       return rep.data.data[0].categorieage;
      }
      else { return " "}
    }catch (error) {
      console.error(error);
    
    }

  }