import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { insertathelete, getAllAthelets } from '../../Service/Athlete/AthleteApi';
import { getcategories } from '../../utils/Categories';
import { uploadimage } from '../../Service/UploadImage/UploadImage';
import { uploadPDF } from '../../Service/ImportPdfs/ImportPdfsApi';
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi'
import { differenceInYears, parseISO } from 'date-fns';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getclub, updatesteps } from '../../Service/Inscription/ClubInscription/clubinscription';
import {  updatenumlicences } from '../../Service/Federation/FederationApi';
export default function FormulaireAtheleteApi() {
  const [specialite, setspecialite] = useState([])
  const [nameSpec, setNameSpec] = useState([])

  const navigate = useNavigate();

  const [formData, setFormData] = useState([
    {
      nom: "",
      prenom: "",
      idClub: localStorage.getItem('idunique'),
      specialites: [],
      datenaissance: "",
      categories_age: [],
      certificatmedicale: "",
      numtele: "",
      lieunaissance: "",
      actnaissance: "",
      image: "",
      cin: '',
      PermissionParentale: "",
      nationalite: "",
      nomParent: "",
      Trampoline_CA: '',
      Parkour_CA: '',
      GR_CA: '',
      GAF_CA: '',
      GAM_CA: '',
      AeroGym_CA: '',
      nbrlicence: 0,
      sexe:''


    },
  ]);
  const [club, setclub] = useState()
  useEffect(() => {
    const fetchClubs = async () => {
      const clubResp = await getclub();
      if (clubResp.status === 200) {
        setclub(clubResp.data.data[0]);
      } else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")
      }

    }
    fetchClubs();
  }, []);

  const ajouterChamp = () => {
    setFormData([...formData, {
      nom: "",
      prenom: "",
      idClub: localStorage.getItem('idunique'),
      specialites: [],
      datenaissance: "",
      categories_age: [],
      certificatmedicale: "",
      numtele: "",
      lieunaissance: "",
      actnaissance: "",
      image: "",
      cin: '',
      PermissionParentale: "",
      nationalite: "",
      nomParent: "",
      Trampoline_CA: '',
      Parkour_CA: '',
      GR_CA: '',
      GAF_CA: '',
      GAM_CA: '',
      AeroGym_CA: '',
      nbrlicence: 0,
      sexe:''

    }]);
  };
  useEffect(() => {
    const fetchsDiscipline = async () => {
      try {
        const respdiscipline = await Getdiscipline();
        setspecialite(respdiscipline.data.data)
      } catch (error) {
        console.error(error);
        toast.error('حدث خطأ أثناء الحصول على البيانات');
      }
    }
    fetchsDiscipline();
  }, []);
  const handleChangeSpecialite = async (personIndex, specialiteValue, indexitem, check) => {
    const newData = [...formData];
    if (check) {

      if (newData[personIndex]) {


        if (newData[personIndex].datenaissance && specialiteValue) {

          const cateAge = await getcategories(newData[personIndex].datenaissance, [specialiteValue]); // Remarquez que nous utilisons un tableau ici pour la spécialité
          if (cateAge !== undefined) {
            newData[personIndex].specialites = [...newData[personIndex].specialites, specialiteValue];

            if (!newData[personIndex]["categories_age"]) {

              newData[personIndex]["categories_age"] = [];
            }
            newData[personIndex][`${[specialiteValue]}_CA`] = cateAge;

            newData[personIndex]["categories_age"][indexitem] = cateAge;
          } else {
            toast.warning("العمر لا يتوافق مع أي فئة عمرية  ")
          }
        }

        setFormData(newData);
      }
    }
    else {

      newData[personIndex].specialites = newData[personIndex].specialites.filter(
        (item) => item !== specialiteValue
      );
      newData[personIndex]["categories_age"][indexitem] = "";

      setFormData(newData);
    }
  };


  const handleChange = async (e, index) => {
    const { name, value } = e.target;
    const newData = [...formData];

    if (name === 'image' || name === 'certificatmedicale' || name === 'actnaissance' || name=== 'PermissionParentale') {
      const file = e.target.files[0];
      newData[index][name] = file;
    } else {
      newData[index][name] = value;
    }
    newData[index]['index'] = index;
    setFormData(newData);
  };


  const supprimerChamp = (index) => {
    const newData = [...formData];

    console.log('1',newData)
    newData.splice(index, 1); // Supprime l'élément à l'index spécifié
    console.log('2',newData)

    setFormData(newData);
  };
  function estMajeur(dateDeNaissance) {

    const dateActuelle = new Date();
    const dateNaissance = new Date(dateDeNaissance);
    const differenceEnMs = dateActuelle - dateNaissance;
    const annees = differenceEnMs / (1000 * 60 * 60 * 24 * 365.25);
    return annees >= 18;
  }
  const verifspecialite = ()=>{
    for (let i = 0; i < formData.length; i++) {
      const personData = formData[i];
      if (personData.specialites.length === 0){
        return {sucess : false ,index : i}
      }

    }
    return {sucess : true ,index : -1}
  }
  const Enregistrer = async () => {
    try {
      const resverification =verifspecialite();
      if(resverification.sucess===false){
        toast.warning(`الرجاء اختيار التخصص الرياضي       `  )

      }else{
      for (let i = 0; i < formData.length; i++) {
        const personData = formData[i];

        // Récupérer tous les athlètes existants
        const respAll = await getAllAthelets();
        if (respAll.status === 200) {


          const existingAthlete = respAll.data.data.find((athlete) => {
            if (personData.cin !== '') {

              return parseInt(personData.cin) === athlete.cin;
            } else {
              const athleteDate = new Date(athlete.datenaissance);
              const personDataDate = new Date(personData.datenaissance);

              // Format dates as desired
              const formattedAthleteDate = athleteDate.toDateString();
              const formattedPersonDataDate = personDataDate.toDateString();


              return (
                personData.nom === athlete.nom &&
                personData.prenom === athlete.prenom &&
                formattedPersonDataDate === formattedAthleteDate &&
                personData.lieunaissance === athlete.lieunaissance &&
                personData.nomParent === athlete.nomParent
              );
            }
          });

          if (existingAthlete) {
            if (personData.cin !== '') {
              alert(`Cet athlète existe déjà (CIN ${personData.cin} déjà utilisé)`);
              return
            } else {
              personData.cin = 0 ;

              alert(
                `Cet athlète existe déjà (${personData.nom} ${personData.prenom} déjà utilisé)`

              );
              return
            }

          }
        }
       
          // Charger les fichiers et enregistrer les données
          const imagedataframe = new FormData();
          imagedataframe.append('image', personData.image);
          
          const now = new Date();
          const formattedDate = `${now.getDate()}${now.getMonth() + 1}${now.getFullYear()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
          const imageName = encodeURIComponent(`${localStorage.getItem('idunique')}_imageAthelete_${formattedDate}`);
          const resp = await uploadimage(imageName, imagedataframe);
          if (resp.status === 200) {

            let fileExtension = personData.image.name.split('.').pop();
            personData.image = `/${imageName}.${fileExtension}`;

            // Upload du fichier d'acte de naissance
            const actnaissancedataframe = new FormData();
            actnaissancedataframe.append('pdf', personData.actnaissance);
            const fileName = encodeURIComponent(`${localStorage.getItem('idunique')}_ActNaissance_${formattedDate}`);
            const resPDF = await uploadPDF(fileName, actnaissancedataframe);

            if (resPDF.status === 200) {
             
              fileExtension = personData.actnaissance.name.split('.').pop();
              
              personData.actnaissance = `pdf/${fileName}.${fileExtension}`;
              // Upload du fichier de permission parentale
              const PermissionParentaledataframe = new FormData();
              PermissionParentaledataframe.append('pdf', personData.PermissionParentale);

              const PermissionParentaleName = encodeURIComponent(
                `${localStorage.getItem('idunique')}_PermissionParentale_${formattedDate}`
              );
              const respPermission = await uploadPDF(PermissionParentaleName, PermissionParentaledataframe);

              if (respPermission.status === 200) {
                
                fileExtension = personData.PermissionParentale.name.split('.').pop();
                personData.PermissionParentale = `pdf/${PermissionParentaleName}.${fileExtension}`;

                // Upload du fichier de certificat médical
                const CMdataframe = new FormData();
                CMdataframe.append('pdf', personData.certificatmedicale);
                const Namefile = encodeURIComponent(
                  `${localStorage.getItem('idunique')}_certificatmedicale__${formattedDate}`
                );
                const resPDFCM = await uploadPDF(Namefile, CMdataframe);

                if (resPDFCM.status === 200) {
                  fileExtension = personData.certificatmedicale.name.split('.').pop();

                  personData.certificatmedicale = `pdf/${Namefile}.${fileExtension}`;
                  
                  // Envoyer formDataToSend au backend
                  personData.specialites.forEach((element) => {
                    personData.nbrlicence++

                    personData[element] = `FTGYM_Licence-${element}_${personData.nom}_${localStorage.getItem('idunique')}_${formattedDate}`;
                    

                  });
                  const indexp = personData.index;
                  delete personData.index;
                  personData.idunique = formattedDate;
                  delete personData.categories_age;
                  delete personData.specialites;


                  const respInsert = await insertathelete(personData);

                  if (respInsert.status === 201) {
                    supprimerChamp(indexp)
              
                  }

                
              }
            }
          }
        }
      }
      updatestep();
     navigate('/PdfFrais');
    }
    } catch (error) {
      console.error(error.message);
      toast.error('حدث خطأ أثناء تسجيل البيانات');
    }
  };
  const updatestep = async () => {
    const data = { step: 4 };
    if (club.stepInscription < data.step) {


      const clubResp = await updatesteps(data);
      if (clubResp.status === 200) {
      }
    }
  }
  return (
    <div>

      <header>
        <div className="image-container">
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

          <div className="info-container">
            <p><strong> الرياضيين  </strong></p>

            <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
          </div>
        </div>
      </header>

      {formData.map((person, index) => (
        <div key={index} className="person-form">
          <form>
            <label>الاسم<span className="required"></span></label>
            <input
              type="text"
              name="nom"
              required
              value={person.nom}
              onChange={(e) => handleChange(e, index)}
            />
            <label>اللقب<span className="required"></span></label>
            <input
            required
              type="text"
              name="prenom"
              value={person.prenom}
              onChange={(e) => handleChange(e, index)}
            />
    <div className="row  mr-2 mb-2">
              <input className='col-2'
                type="radio"
                id={'sexe'}
                name={'sexe'}
                value={1}

                onChange={(e) => handleChange(e, index)}
              
              />
              <label className='col-3'>    ذكر </label>


              <input
                className='col-2'
                type="radio"
                id='sexe'
                name='sexe'
                value={0}

                onChange={(e) => handleChange(e, index)}

              />
              <label className='col-3'>     انثى </label>


              <label>تاريخ الميلاد <span className="required"></span></label>
            <input
              type="date"
              name="datenaissance" // Modifier le nom en "datenaissance"
              value={person.datenaissance}
              onChange={(e) => handleChange(e, index)}
              required
            />
                 <label>مكان الولادة<span className="required"></span></label>
            <input
              type="text"
              name="lieunaissance"
              value={person.lieunaissance}
              onChange={(e) => handleChange(e, index)}
            />

            </div>
            <label>صورة شمسية <span className="required"></span></label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              required
              onChange={(e) => handleChange(e, index)}
            />
            <label >الجنسية<span className="required"></span></label>
            <input
              type="text"
              name="nationalite"
              value={person.nationalite}
              onChange={(e) => handleChange(e, index)}
            />
            <label>  مضمون ولادة <span className="required"></span></label>
            <input
              type="file"
              id="actnaissance"
              name="actnaissance"
              required
              onChange={(e) => handleChange(e, index)}
            />
            <label>   شهادة طبية <span className="required"></span></label>
            <input
              type="file"
              id="certificatmedicale"
              name="certificatmedicale"
              required
              onChange={(e) => handleChange(e, index)}
            />
            <label>    ترخيص ابوي  <span className="required"></span></label>
            <input
              type="file"
              id="PermissionParentale"
              name="PermissionParentale"
              required
              onChange={(e) => handleChange(e, index)}
            />
            <label>   اسم الولي <span className="required"></span> </label>
            <input
              type="text"
              name="nomParent"
              value={person.nomParent}
              required
              onChange={(e) => handleChange(e, index)}
            />




       
            {person.datenaissance && estMajeur(parseISO(person.datenaissance)) ? (
              <div>
                <label>رقم بطاقة الهوية</label>
                <input
                  type="number"
                  name="cin"
                  value={person.cin}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
            ) : null}




            <label>رقم الهاتف<span className="required"></span></label>
            <input
              type="number"
              name="numtele"
              value={person.numtele}
              onChange={(e) => handleChange(e, index)}
            />
            <label>الاختصاصات</label>
            {specialite.map((item, indexitem) => (
              <div>
                <div className='row mb-2' key={indexitem}>
                  <input
                    className='col-1'
                    type="checkbox"
                    id={`parcour_${item.libellerFR}`}
                    name={`specialite[${index}]`}
                    value={item.libellerFR}
                    checked={formData[index].specialites.includes(item.libellerFR)}
                    onChange={(e) => handleChangeSpecialite(index, item.libellerFR, indexitem, e.target.checked)}
                  />
                  <img
                    className='icon col-2'
                    src={item.logo}
                    alt="Club Logo"
                    width="250px"
                    height="250px"
                  />
                  <label className='col-3' htmlFor={`parcour_${item.libellerFR}`}>{item.LibellerAR}</label>
                  <div className='col-6 row'>
                    {person.categories_age[indexitem] ? (
                      <>
                      <p className='col-6'> الفئة العمرية: </p>
                      <p className='col-2'>   {person.categories_age[indexitem]}</p></>
                    ) : null}
                  </div>
                </div>


              </div>
            ))}

            <button onClick={() => supprimerChamp(index)}>مسح</button>
          </form>
        </div>
      ))}
        <div className=' buttonform'>
      <button  onClick={ajouterChamp}>+     إضافة رياضي آخر </button>
              <button onClick={Enregistrer}>حفظ البيانات</button>
     
              </div>
    </div>
  );
} 