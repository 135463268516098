import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { Login, Verifemail } from '../../Service/Login/LoginApi';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function FormulaireLogin() {

    const { register, handleSubmit, reset } = useForm();
    const navigate = useNavigate();
    const [login, setLogin] = useState('')
    const [email, setemail] = useState('')

    const [showpassword, setshowpassword] = useState(false)
    const [showpopup, setshowpopup] = useState(false)

    const verifEmail = async () => {
        setshowpassword(false)

        const response = await Verifemail(encodeURIComponent(login))
        if (response && response.status === 200) {
           setemail(response.data.data[0].email)
            setshowpopup(true)

        }else if(response.status===404){
            toast.error(`${login} هذا النادي غير موجود      `);

        }
        else {
            toast.error(response.data.message);
        }
    }
    const onSubmit = async (data) => {
        const response = await Login(data)
        if (response && response.status === 200) {
            if (response.data.user.valide === -1) {
                toast.warning('تم حظر حساب ')
            } else {
                localStorage.setItem('idunique', data.login);

                navigate(`/homeclub`);
                window.location.reload()
            }


        }
        else {
            toast.error(response.data.message);
        }
    };
    return (
        <div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

            <form onSubmit={handleSubmit(onSubmit)} >


                <div className="form-group">
                    <label htmlFor="password"> رمز تسجيل <span className="required"></span></label>
                    <input
                        type="text"
                        required
                        {...register("login")}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword"> كلمة السر<span className="required"></span></label>
                    <input
                        type="password"
                        required
                        {...register("password")}

                    />
                </div>

                <button type='submit'>تسجيل الدخول</button>
                <a className='lien-bleu-souligne ' onClick={() => setshowpassword(true)}>نسيت كلمة السر</a>
            </form>

            {showpassword && (



                <div className="popup">
                    <div className="popup-container">
                        <p>
                            لإعادة تعيين كلمة السر الخاصة بك، يرجى إدخال رمز تسجيل الدخول الخاص بك
                        </p>
                        <input type='text' onChange={(e) => setLogin(e.target.value)} />
                        <button onClick={verifEmail}>  إعادة تعيين كلمة السر</button>
                    </div>

                </div>
            )}
            {showpopup && (
            <div className="popup">
                    <div className="popup-container">
                        <p>
                      تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني {email}
                        </p>
                      
                        <button onClick={()=>setshowpopup(false)}>   غلق</button>

                    </div>

                </div>
            )}

        </div>
    )
}
