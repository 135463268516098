import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { deletepub, insertpub, updatePub, uploadPDFPublication } from '../../Service/Publication/PublicationApi';

export default function FormPublication({ showModel, setShowModel, data }) {
    const [filename, setFilename] = useState('');
    const [state, setsate] = useState(false);

    const { register, handleSubmit, reset } = useForm();
    useEffect(() => {
        if (data?.id) {
            reset({
                id: data.id,
                image: data.image,
                titre: data.titre,
                subtitre: data.subtitre,
                description: data.description,
                pdf: data.pdf,
                type: data.type,

            });

        } else {
            reset({
                image: "",
                titre: "",
                subtitre: "",
                description: "",
                pdf: "",
                type: "",
            });
        }
    }, [data]);
  

    const onSubmit = async (data) => {
        try {
            if (data?.id) {
                let pdfData;
                const updateData = new FormData();
                updateData.append('titre', data.titre);
                updateData.append('subtitre', data.subtitre);
                updateData.append('id', data.id);
                updateData.append('description', data.description);
                updateData.append('type', 'newslist');
    
                // Vérifier si le fichier PDF a été modifié
                if (data.pdf[0] instanceof File) {
                    const pdfExtension = data.pdf[0].name.split('.').pop();
                    const modifiedPdfName = `${filename}.${pdfExtension}`;
                    const modifiedPdf = new File([data.pdf[0]], modifiedPdfName, { type: data.pdf[0].type });
    
                    const formDataPDF = new FormData();
                    formDataPDF.append('pdf', modifiedPdf);
    
                    const responsePdf = await uploadPDFPublication(formDataPDF);
    
                    if (responsePdf.status === 200) {
                        setsate(true);
                        const filepath = `pdf/${modifiedPdfName}`;
                        updateData.append('pdf', filepath);
                        pdfData = filepath;
                    }
                } else {
                    // Si le PDF n'a pas été modifié
                    updateData.append('pdf', data.pdf);
                    pdfData = data.pdf;
                    setsate(true);
                }
    
                // Si l'image a été modifiée
                if (data.image[0] instanceof File) {
                    const imageExtension = data.image[0].name.split('.').pop();
                    const modifiedImageName = `${data.image[0].name}${Date.now()}.${imageExtension}`;
                    const modifiedImage = new File([data.image[0]], modifiedImageName, { type: data.image[0].type });
                    updateData.append('image', modifiedImage);
    
                    await deletepub(data.id);
                    const response = await insertpub(updateData);
    
                    if (response.status === 201) {
                        toast.success('تم الإدراج بنجاح');
                        setFilename('');
                        setShowModel(false);
                        window.location.reload();
                    } else {
                        toast.error(response?.data?.message || 'حدث خطأ');
                    }
                } else {
                    // Si l'image n'a pas été modifiée
                    const dataSend = {
                        titre: data.titre,
                        subtitre: data.subtitre,
                        id: data.id,
                        description: data.description,
                        type: "newslist",
                        pdf: pdfData,
                        image: data.image
                    };
    
                    const response = await updatePub(dataSend);
    
                    if (response.status === 200) {
                        toast.success('تم الإدراج بنجاح');
                        setFilename('');
                        setShowModel(false);
                        window.location.reload();
                    } else {
                        toast.error(response?.data?.message || 'حدث خطأ');
                    }
                }
            } else {
                if (data.image[0]) {
                    const formData = new FormData();
    
                    // Modify the original filename by appending the timestamp for both image and PDF
                    const imageExtension = data.image[0].name.split('.').pop();
    
                    const modifiedImageName = `${data.image[0].name}${Date.now()}.${imageExtension}`;
    
                    // Create new File objects with the modified names
                    const modifiedImage = new File([data.image[0]], modifiedImageName, { type: data.image[0].type });
    
                    formData.append('titre', data.titre);
                    formData.append('subtitre', data.subtitre);
                    formData.append('image', modifiedImage);
                    formData.append('description', data.description);
                    formData.append('type', 'newslist');
    
                    try {
                        //uploadfile
                        if (data.pdf instanceof File) {
    
                            const pdfExtension = data.pdf[0].name.split('.').pop();
    
                            const modifiedPdfName = `${filename}.${pdfExtension}`;
                            const modifiedPdf = new File([data.pdf[0]], modifiedPdfName, { type: data.pdf[0].type });
    
                            const formDataPDF = new FormData();
                            formDataPDF.append('pdf', modifiedPdf);
    
    
                            const responsepdf = await uploadPDFPublication(formDataPDF);
                            if (responsepdf.status === 200) {
                                setsate(true)
                                const filepath = `pdf/${modifiedPdfName}`
                                formData.append('pdf', filepath);
                                window.location.reload()

                            }
                        } else {
                         
                            setsate(true)
    
                        }
                      //  console.log('data1',state)
                        if (state) {
                           
                            // Use the correct API endpoint and method for the file upload
                            const response = await insertpub(formData);
    
                            if (response.status === 201) {
                                // Remove the custom header after the request is sent
                                toast.success('تم الإدراج بنجاح');
                                setFilename('');
                                setShowModel(false);
                                window.location.reload()
                            } else {
                                toast.error(response?.data?.message || 'حدث خطأ');
                            }
                        }
                    } catch (error) {
                        // Handle errors, e.g., network issues
                        toast.error('حدث خطأ في الاتصال');
                    }
                } else {
                    toast.warning('الصورة المطلوبة            ')
                }
            }
        } catch (error) {
            // Gérer les erreurs, par exemple les problèmes de réseau
            toast.error('حدث خطأ في الاتصال');
        }
    };
    
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <Modal show={showModel} onHide={() => setShowModel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> منشور</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                        <div className="form-group">
                            <label htmlFor="image">صورة<span className="required"></span></label>
                            <input type="file" id="image" name="image" accept="image/*" {...register('image')} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="titre">عنوان<span className="required"></span></label>
                            <input type="text" id="titre" name="titre" required {...register('titre')} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="subtitre">العنوان الفرعي<span className="required"></span></label>
                            <input type="text" id="subtitre" name="subtitre" required {...register('subtitre')} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">وصف</label>
                            <input type="text" id="description" name="description"  {...register('description')} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="pdf">اسم الملف </label>
                            <input
                                type="text"
                                id="pdf"
                                name="pdf"
                                value={filename}
                                onChange={(e) => setFilename(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="pdf">ملف PDF</label>
                            <input
                                type="file"
                                id="pdf"
                                name="pdf"
                                disabled={!filename}
                                {...register('pdf')}
                            />
                        </div>

                        <div className="form-button">
                            <button type="submit" className="submit-btn">
                                تسجيل
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}
