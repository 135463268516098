import React, { useState } from 'react'
import AjoutResponsable from '../AjoutResponsable/AjoutResponsable'
import ClubsTables from '../Clubs/ClubsTables'
import FedrationFile from '../FedrationFile/FedrationFile'
import AjoutRoles from '../RoleFederation/AjoutRoles'
import ModifMDP from '../ModifMDP/ModifMDP'

export default function Responsable() {
    const [Modelresponsable  ,setModelresponsable]= useState(false)    
    const [ModelRole ,setModelRole]= useState(false)
    const [ShowModelmodifiermotdepasse, setShowModelmodifiermotdepasse] = useState(false);

  return (
    <div> 
      <button onClick={()=>setModelresponsable(true)}>إضافة مستخدم</button>{' '}
      <button onClick={()=>setModelRole(true)}>أضف مهنة </button>{' '}
      <button onClick={()=>setShowModelmodifiermotdepasse(true)}>تعديل كلمة المرور</button>


      <ModifMDP showModel={ShowModelmodifiermotdepasse} setShowModel={setShowModelmodifiermotdepasse} email={localStorage.getItem('email')}/>

      <AjoutResponsable showModal={Modelresponsable} setshowModal={setModelresponsable} />
      <AjoutRoles showModal={ModelRole} setshowModal={setModelRole} />
      <div className="yogaPlace-section">
        <ClubsTables />
      </div>
      <div className="best-class-club mb-3 ml-4 " >
  <div className="row best-class-sections-detail">
    <div className="col-lg-8 best-class-club-desc">
      <div >
      < FedrationFile />


      </div>
    </div>
  
  </div>
</div>
    </div>
  )
}
