import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Getdiscipline} from '../../Service/Discipline/DisciplineApi'
import html2pdf from 'html2pdf.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Certificat_pdf({item}) {
  const { REACT_APP_API_URL } = process.env;
  const url = REACT_APP_API_URL
  const handleButtonClick = item => {
    const newTab = window.open(`${process.env.REACT_APP_API_FRONT_URL}Certificats/${encodeURIComponent(JSON.stringify(item.QRdata))}`, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      toast.error('منع فتح نافذة جديدة');
    }
  };
  return (
    <div>
   
  
        <div id={item.numLicence}>
 
            <div className='licence' style={{backgroundColor:"white", height:'100vh !important'}}>
              <div className="certificate" id={`pdf-content${item.numLicence}`}  style={{backgroundColor:"white" }}>
                {/* Insert the rest of the certificate content here */}
                <div className='header'  style={{backgroundColor:"white"}}>
                  <div className="info-container" style={{backgroundColor:"white"}}>
                    <div className="arabic-info" style={{backgroundColor:"white"}}></div>
                    <div className="image-container" style={{backgroundColor:"white"}}>
                    <h1 style={{backgroundColor:"white"}}> {item.specFr === 0  ?'وصل '  : null }اجازة رياضي {item.specFr !== 0  ?'رقمية '  : null }</h1>

                      <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
                    </div>
                    <div className="french-info" style={{backgroundColor:"white"}}>
                      <img src="/images/logo_FTG.png" alt="Image" width="250" height="250" id="logo-image" />
                      
                    </div>
                   </div>
                </div>
                <div className="content"  style={{backgroundColor:"white"}}>
                <img src={url+item.image} style={{ width: '100px', height: '101px' }} alt="Image description" />
                <h2>{item.numLicence}</h2>
                  <p><strong>إسم واللقب </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.nom} &nbsp;&nbsp; {item.prenom}</p>
                  { item.cin !== 0 ? (    <p> <strong> رقم بطاقة الهوية </strong>   :  &nbsp;&nbsp; &nbsp;&nbsp;{item.cin}  </p>):null}
                  <p><strong>تاريخ الولادة </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{new Date(item.datenaissance).getFullYear()}-{(new Date(item.datenaissance).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-{new Date(item.datenaissance).getDate().toString().padStart(2, '0')} </p>
                  
                  <p><strong> مكان الولادة </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.lieunaissance}</p>
                  <p><strong>  الجنسية </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.nationalite}</p>
                  <p><strong>  إسم الجمعية أو النادي </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.clubnom }</p>
                  <p><strong>الاختصاص </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.spec}</p>
                  <p><strong>الفئة العمرية </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.cat_age}</p>
                </div>
               <div>
                
               </div>
                { item.specFr > 0 ?(
                <div   style={{backgroundColor:"white"}}>
                  <QRCode  value={process.env.REACT_APP_API_FRONT_URL+'Certificats/'+encodeURIComponent(JSON.stringify(item.QRdata))} size={128} />
                  <button onClick={() => handleButtonClick(item)}></button>
                </div>):null}
              </div>
            </div>
    </div>
    
        
       </div>
  )
}
