import React, { useState,useEffect } from 'react'
import { useForm  } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { loginFederation,getroles } from '../../Service/Federation/FederationApi';
import { toast } from 'react-toastify';
export default function LoginAdmin() {
  const [roles ,setroles]=useState([]);
  const { register, handleSubmit,reset    } = useForm();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchRoles = async()=>{
    const Resp = await getroles();
    if(Resp.status === 200){
      
      setroles(Resp.data.data); 
    }else{
    alert("no role found")
    }
    
    }  
    fetchRoles();  
}, []);

  const onSubmit = async (data) => {
      const response = await loginFederation(data)
     if(response && response.status === 200){
        //cree sesion 
        const respdata=response.data.user ; 
        respdata.role=data.role;
        const id=`FTGYM-${data.role}`;
        localStorage.setItem('IDrole',id)  ;
        localStorage.setItem('email',data.email)
        localStorage.setItem('nom',response.data.user.nom)
        localStorage.setItem('prenom',response.data.user.prenom)

        if(parseInt(data.role)=== 1){
    
          navigate(`/home/SecretariatGeneral`); 
          window.location.reload();
        }
        else if(parseInt(data.role)=== 2){
         

          navigate(`/home/responsable`); 
          window.location.reload();
        }
  
        }
        else{
          toast.error('الرجاء إدخال بيانات صحيحة '); 
      }}
  return (
    <div>
    <form  onSubmit={handleSubmit(onSubmit)} >
        
 
            <div className="form-group">
                 <label htmlFor="password">  بريد إلكتروني<span className="required"></span></label>
                 <input
                 type="text"
                 required
                 {...register("email")} 
                 />
             </div>
             <div className="form-group">
                 <label htmlFor="confirmPassword"> كلمة السر<span className="required"></span></label>
                 <input
                 type="password"
                 required
                 {...register("password")} 
                 
                 />
             </div>
             <div className="form-group">
                 <label htmlFor="confirmPassword">  المنصب<span className="required"></span></label>
                 <select
                    {...register("role")} 
                >
                  <option>اختر المهنة</option>
                    {roles.map((item, index) => (
                        <option key={index} value={item.id}>
                            {item.libeller}
                        </option>
                    ))}
                    </select>
             </div>
             <button type='submit'>تسجيل الدخول</button>
             </form>
            
            </div>
  )
}
