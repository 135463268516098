import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../shared/Header';
import { getatheletebyid, getoneAthlete } from '../../Service/Athlete/AthleteApi';

export default function Certificats() {
  const { data } = useParams();
  
  const parsedData = JSON.parse(decodeURIComponent(data));
  const [valide ,setvalide]=useState(false)
  useEffect(()=>{
    if(parsedData){
      getathlete()
    }
  },[parsedData])
  const getathlete =async()=>{
   const res = await getoneAthlete(parsedData.id)
   if(res.status === 200){
    const specFr = res.data.data[0][`${parsedData.libellerFR}_valide`];
    const v = specFr === 1 ? true : false ;
    setvalide(v)
   }
 
  }
  return (
 

    <div className="best-class-club mb-3 ml-4" >
      <Header />

    <div className="row best-class-sections-detail center-text">
      <div className="col-lg-8 best-class-club-desc">
        <div className="row center-text">
         <h1 className='section-title'> الاجازة {parsedData.numlicence}  </h1>
        
            <h2 className='section-title '>{parsedData.nom}{' '}{parsedData.prenom} </h2>
   
           { valide ?   <img src='/images/valide.png' style={{width:'500px ' ,height:'300px'}} /> : <p>nonvalide</p>}
    
       
        </div>
      </div>
  
      </div>
    </div>


  );
}
/*<div>
<Header />
<div className=''>
<p>{parsedData.nom} {parsedData.prenom}</p>
<p style={{'color': 'green'}}>Valide</p>
</div>
</div>*/