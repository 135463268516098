import React, { useEffect, useState } from 'react'
import { GetPubAcceuil, deletepub } from '../../Service/Publication/PublicationApi';
import { toast } from 'react-toastify';
import FormPublication from './FormPublication';

export default function Publication() {
    const [Publications , setPublications]=useState([]); 
    const [showModel , setshowModel]=useState(false); 
    const [data , setdata]=useState({}); 

    const { REACT_APP_API_URL } = process.env;
    const url = REACT_APP_API_URL
    useEffect(() => {
        const fetchPub = async () => {
            const Resp = await GetPubAcceuil();
            if (Resp.status === 200) {
                const listPublications = Resp.data.data;

                if (Resp.data.data.length !== 0) {
                    const banner_news = Resp.data.data.find(item => item.type === "news_banner")
                    const list_news = Resp.data.data.filter(item => item.type === "newslist").reverse();
                    setPublications(list_news);
                    /*if (banner_news) {
                        setNewsBanner(banner_news)
                    }*/
                }



            }

        }
        fetchPub();
    }, []);
    useEffect(() => {
      if(showModel === false){
        setdata({})
         }
      
  }, [showModel]);
  
    const openPDFInNewTab = (pdfFileName) => {
      const FN = encodeURIComponent(pdfFileName);
      const pdfUrl = `${url}${pdfFileName}`;
  
      const newTab = window.open(pdfUrl, '_blank');
  
      if (!newTab) {
        alert('Popup blocker prevented opening a new tab.');
      }
    };
    const handeldelete =async(id) => {
      const resp = await deletepub(id) 
      if(resp.status === 200){
        toast.success("تم حذف  بنجاح        ")
        window.location.reload()     
       }else{
        toast.error("الحذف غير ناجح")
      }
    }
    const handelupdate = async(item)=>{
      setdata(item);
      setshowModel(true)
    }

    return (
       <div style={{ 'height': '500px', 'overflowY': 'auto' }}>
                    <button  onClick={()=>setshowModel(true)}>إضافة منشور</button>

        <table   >
          <thead>
            <tr>
              <th>صورة</th>
              <th className='center-text'>عنوان</th>
              <th className='center-text'> العنوان الفرعي </th>
              <th className='center-text'> وصف </th>
              <th className='center-text'> ملف PDF </th>

            </tr>
          </thead>
          <tbody>
            {Publications?.map((item) => (
              <tr key={item.id}>
                 <td className='center-text imageicon'> <img src={`${url}logo${item.image}`}/></td>
                <td className='center-text' style={{maxWidth:'100px'}}> {item.titre}</td>
                <td className='center-text'style={{maxWidth:'100px'}}> {item.subtitre}</td>
                <td className='center-text' style={{maxWidth:'200px'}}> {item.description}</td>
                <td className='center-text'> {item.pdf !== '' ? (
<a className='join-us-btn' onClick={()=>openPDFInNewTab(item.pdf)}>لمزيد من التفاصيل   </a>
):null}</td>

<td><button onClick={()=>handeldelete(item.id)}>حذف </button></td>
<td><button onClick={()=>handelupdate(item)}>تحديث </button></td>


              </tr>
            ))}

            <FormPublication showModel={showModel} setShowModel={setshowModel}  data={data} />
          </tbody>
        </table>
      </div>
    )
}
