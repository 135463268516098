
import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function Login(data) {

    try {
     
        const response = await axios.post(`${url}Club/login`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

/***
 * verif email pour reset password 
 */
export async function Verifemail(idclub) {

    try {
  
        const response = await axios.get(`${url}Club/verifemail/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   