import React, { useState } from 'react'
import { useParams ,useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetpassword } from '../../Service/Inscription/ClubInscription/clubinscription';

export default function Resetpassword() {
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { id } = useParams();
    const [message, setMessage] = useState(false);

    const updatepasswordfunction =async(e)=>{
        e.preventDefault();

        if (password === confirmPassword) {
            
            const idclub = decodeURIComponent (id);

            const data ={id:encodeURIComponent(idclub), password:password}
            const response = await resetpassword(data)
             if (response && response.status === 200) {
                navigate(`/home`);
                window.location.reload()
    
            }
            else {
                toast.error(response.data.message);
            }
        }
        else {

            setMessage(true);
        }
    

    }
    return (
        <div>
            <form >
                <div>
                    <label htmlFor="password">كلمة السر:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                    />
                </div>
                <div>
                    <label htmlFor="confirmPassword">تأكيد كلمة السر</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                    />
                </div>
                <div>
                    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>{message ? "كلمة المرور غير مطابقة" : null}</span>

                </div>
                <button onClick={(e) => { updatepasswordfunction(e) }}>حفظ</button>
            </form>
        </div>
    )
}
