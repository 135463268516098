import React, { useEffect, useState } from 'react'
import Header from '../../shared/Header';
import { getclub, getcount, getinfoclub, updatesteps } from '../../Service/Inscription/ClubInscription/clubinscription';
import html2pdf from 'html2pdf.js';
import { getFrais } from '../../Service/Federation/FederationApi';

import PdfFooter from '../../shared/PdfFooter';
import PdfHeader from '../../shared/PdfHeader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tafgeet } from "tafgeet-arabic";
import { useNavigate } from "react-router-dom";


export default function PdfFrais() {
  const [club, setclub] = useState([]);
  const [frais, setfrais] = useState([]);
  const [count, setcount] = useState([]);
  const [totalfrais, settotalfrais] = useState(0)
  const navigate = useNavigate()


  useEffect(() => {
    const fetchClubs = async () => {
      const clubResp = await getinfoclub();
      
      if (clubResp.status === 200) {
        if(clubResp.data.data.length !== 0){
          setclub(clubResp.data.data[0]);

        }else{
          const Resp =await getclub(); 
          if(Resp.status === 200){
           
            setclub(Resp.data.data[0]);

          }
        }
      } else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")
      }

    }
    fetchClubs();
  }, []);
  useEffect(() => {
    const fetchFrais = async () => {
      const FraisResp = await getFrais();
      if (FraisResp.status === 200) {
        setfrais(FraisResp.data.data);

      } else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")

      }
      updatestep();
    }
    fetchFrais();


  }, []);
  useEffect(() => {
    const fetchcount = async () => {
      const countResp = await getcount();
      if (countResp.status === 200) {

        setcount(countResp.data.data);

      } else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")

      }

    }
    fetchcount();
  }, []);
  useEffect(() => {
    const total = ((count.NE) * frais.fraisLC_Entraineur) + (count.NA * frais.fraislicence) + frais.fraisannuelle || 0;
    const stringText = new Tafgeet(total, 'TND').parse()
    settotalfrais(stringText)
  }, [count]);
  const updatestep = async () => {
    const data = { step: 3 };
    if (club.stepInscription < data.step) {


      const clubResp = await updatesteps(data);
      if (clubResp.status === 200) {
        console.log("stepupdated")
      } else {
        toast.error(" نحن آسفون، يبدو أن هناك مشكلة")

      }
    }
  }
  const download = () => {
    const element = document.getElementById('pdf-content');
    html2pdf()
      .set({
        margin: 1,
        filename: ` إشتراك.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { useCORS: true }
      })
      .from(element)
      .save();
    updatestep();
  }
  const handleClick = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth' // You can use 'auto' or 'instant' instead of 'smooth' for different scrolling behavior
    });
    navigate('/Homeclub')
  };
  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

      <strong style={{ color: 'red' }}>يجب طباعة هذا الملف وتوقيعه وبعد ذلك يجب تسليمه إلى الاتحاد</strong>
      <button onClick={download}>تحميل</button>
      <div className="pdf-content2" id="pdf-content">
        <PdfHeader nom=" معاليم انخراط و الاجازات  " />

        <div className='mr-5 mb-2'>


      

          <p> إسم الجمعية أو النادي  : {' '}{club.nomClub }</p>
          <p> إسم الرئيس  : {' '}{club.nom}{' '} {club.prenom}</p>
          <p>العنوان :{' '} {club.adresse}</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ margin: '0' }}>الهاتف : {' '}{club.NmTelephone}</p>
            <p style={{ margin: '0px 20px 0px 10px' }}>
              &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              الفاكس: {' '}{club.fax}
            </p>
          </div>
          <p>البريد الإلكتروني : {' '}{club.Email}</p>
        </div>
        <div className='mr-5'>
          <p>1. معلوم الانخراط السنوي بالجامعة:  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{frais.fraisannuelle} دينار</p>
          <p>2.معلوم اجاز ة: &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{frais.fraislicence} دينار</p>
          <p>3.معلوم اجازة مدربين أو مسيرين &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{frais.fraisLC_Entraineur} دينار </p>
        </div>
        <div className='mr-5'>
          <table>
            <thead>
              <tr>
                <th className='text-center'>عدد الاجازات الخاصة بالرياضيين </th>
                <th className='text-center'> عدد  الاجازات الخاصة مدربين و مسيرين</th>
                <th className='text-center'> المبلغ الإجمالي</th>
              </tr>
            </thead>
            <tbody>

              <tr >
                <td className='text-center' >{count.NA}*{frais.fraislicence} = {count.NA * frais.fraislicence}</td>
                <td className='text-center'>{count.NE}*{frais.fraisLC_Entraineur} = {(count.NE) * frais.fraisLC_Entraineur}</td>
                <td className='text-center'>{((count.NE) * frais.fraisLC_Entraineur) + (count.NA * frais.fraislicence) + frais.fraisannuelle}</td>
              </tr>


            </tbody>
          </table>
        </div>
        <div className='mr-5 mt-3'>
          <div>حدد مبلغ الالتزام بـ {totalfrais} </div>
          <div> <p>يقع تحويل  المبلغ  الى الحساب البنكي بالشركة التونسية البنك فرع مدينة العلوم تحت رقم
          </p><p>10010124110344778809 </p>
          </div>
          <br /> <br />
        </div>


        <PdfFooter />
      </div>
      <div className='row   mt-3 mr-5'>
        <div className='col-9'>

          <button onClick={download}>تحميل</button>
          <strong style={{ color: 'red' }}>يجب طباعة هذه الوثيقة و ختمها ثم تحميلها علي المنصة    </strong>
        </div>

        <button className='col-2 ' onClick={handleClick}>التالي</button>

      </div>


    </div>
  )
}
