import React, { useState,useEffect} from 'react';
import { GetRoles, getdirectionbyclub } from '../../Service/Directionclub/DirectionclubApi';
import { getdirection,updateDirection,deletedirection } from '../../Service/Directionclub/DirectionclubApi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate  } from "react-router-dom"; 

export default function TableauDirection() {
   const [persons, setPersons] = useState([]);
   const [roles , setroles]=useState([])
  const [editableRowId, setEditableRowId] = useState(null);
  const navigate = useNavigate();

  const valide = localStorage.getItem('validation')
  useEffect(() => {
    const fetchRoles = async()=>{
     const roleResp = await GetRoles();
     if(roleResp.status === 200){
        setroles(roleResp.data.data); 
     }else{
      toast.error("نحن آسفون، يبدو أن هناك مشكلة")

     }
     
     }  
     fetchRoles();  
   }, []);
   useEffect(() => {
    const fetchdirection = async()=>{
     const response = await getdirectionbyclub();

     if(response.status === 200){
        setPersons(response.data.data); 
     }else{
      toast.error("نحن آسفون، يبدو أن هناك مشكلة")

     }
     
     }  
     fetchdirection();  
   }, []);
  const handleEditClick = (id) => {

    setEditableRowId(id);
  };
  
  const handleSaveClick = async (id) => {
    // extract le personne avec id
    const updatedPerson = persons.find((person) => person.id === id);


    updatedPerson.role=updatedPerson.code;

    delete updatedPerson.code;

    const resp = await updateDirection(updatedPerson); 
        if (resp.status === 201){
           window.location.reload();
        }
        else{
          toast.error("نحن آسفون، يبدو أن هناك مشكلة")
        }
 
  };

  const handeldelete=async(id)=>{
   
    const resp = await deletedirection(id);
    if(resp.status===200){
        window.location.reload();

    }else{
      toast.error("نحن آسفون، يبدو أن هناك مشكلة")
    }
  }
  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const updatedPersons = persons.map((person) =>
      person.id === id ? { ...person, [name]: value } : person
    );
    setPersons(updatedPersons);
  };

  return (
    <div>
<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

         <header>
<div className="image-container">
 
<div className="info-container">

 <p><strong >قائمة  المسيرين     </strong></p>

 <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
 </div>

</div>

</header>
{}
       {valide < 2 ? (<div className='row mr-5'  onClick={() => { navigate('/fromulairedirection') }}><button>إضافة أعضاء الإدارة</button></div>  ):null}
      <table>
        <thead>
          <tr>
            <th className='center-text'>الاسم</th>
            <th className='center-text'>اللقب</th>
            <th className='center-text'>الجنسية</th>
            <th className='center-text'>رقم بطاقة الهوية</th>
            <th className='center-text'>تاريخ الاصدار</th>
            <th className='center-text'> مكان الولادة</th>
            <th className='center-text'>تاريخ الولادة</th>
            <th className='center-text'>الوظيفة</th>
            <th className='center-text'>رقم الهاتف</th>
            <th className='center-text'>البريد الإلكتروني</th>
           
          </tr>
        </thead>
        <tbody>
          {persons.map((person) => (
            <tr key={person.id}>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="text"
                    name="nom"
                    value={person.nom}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}
                  />
                ) : (
                  person.nom
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="text"
                    name="prenom"
                    value={person.prenom}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  person.prenom
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="text"
                    name="nationalite"
                    value={person.nationalite}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  person.nationalite
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="number"
                    name="cin"
                    value={person.cin}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  person.cin
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="date"
                    name="datecin"
                    value={person.datecin}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                      `${new Date(person.datecin).getFullYear()}-${(new Date(person.datecin).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-${new Date(person.datecin).getDate().toString().padStart(2, '0')}`
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="text"
                    name="lieunaissance"
                    value={person.lieunaissance}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  person.lieunaissance
                )}
              </td>
              <td className='center-text'>
              {editableRowId === person.id ? (
                    <input
                        type="date"
                        name="datenaissance"
                        value={person.datenaissance}
                        onChange={(e) => handleChange(e, person.id)}
                        style={{ width: '100px' }}
                    />
                    ) : (
                    `${new Date(person.datenaissance).getFullYear()}-${(new Date(person.datenaissance).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}-${new Date(person.datenaissance).getDate().toString().padStart(2, '0')}`
                    )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <select
                    name="role"
                    value={person.code}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  >
                    <option value=""> اختر الوظيفة</option>
                    {roles.map((role, roleIndex) => (
                      <option key={roleIndex} value={role.code}>
                        {role.role}
                      </option>
                    ))}
                  </select>
                ) : (
                  person.role
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="tel"
                    name="numTelephone"
                    value={person.numTelephone}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  person.numTelephone
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="text"
                    name="email"
                    value={person.email}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  person.email
                )}
              </td>
              {valide < 2 ?(
                <>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <button onClick={() => handleSaveClick(person.id)}>حفظ</button>
                ) : (
                  <button onClick={() => handleEditClick(person.id)}>تعديل</button>
                )}
              </td>
              
              
              <td> <button onClick={() => handeldelete(person.id)}>مسح </button> </td>
              </>
              ):null }
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
  
}
