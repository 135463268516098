import React, { useState, useEffect } from 'react'
import { listeGouvernorat } from '../../utils/gouvernorat';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CreateClub, insertpassword } from '../../Service/Inscription/ClubInscription/clubinscription';
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function FormulaireClubs() {

    const { register, handleSubmit, reset } = useForm();
    const [popup, setpopup] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(false);
    const [popupvalide, setpopupvalide] = useState(false);
    const [popupinvalide, setpopupinvalide] = useState(false);

    const [specialite, setspecialite] = useState([])
    const [checkboxancien, setcheckboxancien] = useState(0)
    const [email, setemail] = useState('')
    const [idunique_id, setidunique] = useState('')

    const navigate = useNavigate();

    let choisi = [];

    useEffect(() => {
        const fetchsDiscipline = async () => {
            try {
                const respdiscipline = await Getdiscipline();
                setspecialite(respdiscipline.data.data)
            } catch (error) {
                toast.error('نحن آسفون، يبدو أن هناك مشكلة');
            }
        }
        fetchsDiscipline();
    }, []);

    const changespeclialite = (item) => {
        choisi.push(item)
    }
    const onSubmit = async (data) => {
        if(choisi.length === 0){
            toast.warning('اختر تخصصات النادي            ')
        }else{
        const specialiteValue = choisi.join(',');

        const id = `FTGYM-${data.nomClub}${new Date().toISOString().slice(0, 10).replace(/-/g, "")}`;
        setidunique(id)
        const saison = localStorage.getItem('saison')
        const formData = new FormData();
        alert(data.nomClub)
        formData.append("gouvernorat", data.gouvernorat);
        formData.append("nomClub", data.nomClub);
        formData.append("logo", data.logo[0]);
        formData.append("adresse", data.adresse);
        formData.append("Numvisa", data.Numvisa);
        formData.append("dateVisa", data.dateVisa);
        formData.append("NmTelephone", data.NmTelephone);
        formData.append("fax", data.fax);
        formData.append("Email", data.Email);
        formData.append("specialite", specialiteValue);
        formData.append("numInscription", id);
        formData.append("stepInscription", 1);
        formData.append("saison", saison)
        formData.append("nbrlicence", data.nbrlicence)
        formData.append("ancien", checkboxancien)

        // Store the unique ID in localStorage


        // Make the HTTP request to create the club
        const response = await CreateClub(id, formData);

        if (response && response.status === 201) {
            // Reset the form and show the popup
            // localStorage.setItem('idunique', id);
            setemail(data.Email)
            reset();
            setpopup(true);
        } else if (response && response.status === 400) {
            setidunique(response.data.data)
            setpopupinvalide(true)
        }
        else {
            toast.error(response?.data?.message || 'حدث خطأ أثناء إنشاء النادي');
        }
    }
    };

    const createcompte = async (e) => {

        e.preventDefault();

        if (password === confirmPassword) {
            /// enregistre 

            const datap = { password: password, id: idunique_id, email: email }
            const resp = await insertpassword(datap)
            if (resp.status === 200) {
                setpopup(false)
                setpopupvalide(true);
            } else {

                toast.error('أعد المحاولة')
            }

        }
        else {

            setMessage(true);
        }
    }
    return (
        <div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

            <div className="form-container">

                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">

                    <div className="form-group">
                        <label for="wilaya">الولاية <span className="required"></span></label>
                        <select
                            {...register("gouvernorat")}
                        >
                            <option value="">اختر الولاية </option>
                            {listeGouvernorat.map((gouvernorat, index) => (
                                <option key={index} value={gouvernorat}>
                                    {gouvernorat}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="nom_club">إسم الجمعية أو النادي <span className="required"></span></label>
                        <input type="text" id="nom_club" name="nom_club" required {...register("nomClub")} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="logo">الشعار <span className="required"></span></label>
                        <input
                            type="file"
                            id="logo"
                            name="logo"
                            accept="image/*"
                            {...register("logo")}
                        />
                    </div>
                    <div className="row  mr-2">

                        <input className='col-1'
                            type="radio"
                            id={'ancien'}
                            name={'ancien'}
                            onClick={() => setcheckboxancien(0)}
                            {...register("ancien")}
                        />
                        <label className='col-3'>     انخراط </label>


                        <input
                            className='col-1'
                            type="radio"
                            id={'ancien'}
                            name={'ancien'}
                            onClick={() => setcheckboxancien(1)}
                            {...register("ancien")}
                        />
                        <label className='col-3'>   إعادة إنخراط </label>



                    </div>
                    <div className="form-group">
                        <label for="adresse" >العنوان  <span className="required"></span></label>
                        <input required type="text" id="adresse" name="adresse" {...register("adresse")} />
                    </div>
                    <div className="form-group">
                        <label for="num_tasheera">رقم التأشيرة <span className="required"></span></label>
                        <input type="text" id="num_tasheera" name="num_tasheera" required {...register("Numvisa")} />
                    </div>
                    <div className="form-group">
                        <label for="date_tasheera">تاريخ التأشيرة <span className="required"></span></label>
                        <input type="date" id="date_tasheera" name="date_tasheera" required {...register("dateVisa")} />
                    </div>
                    <div className="form-group">
                        <label for="telephone">الهاتف: <span className="required"></span></label>
                        <input type="number" id="telephone" name="telephone" required {...register("NmTelephone")} />
                    </div>
                    <div className="form-group">
                        <label for="fax">الفاكس: <span className="required"></span></label>
                        <input type="number" id="fax" required name="fax" {...register("fax")} />
                    </div>
                    <div className="form-group">
                        <label for="email">البريد الإلكتروني <span className="required"></span></label>
                        <input type="email" id="email" name="email" required {...register("Email")} />
                    </div>
                    <div className="form-group">
                        <label for="nbrlicence">  عدد الاجازات  المطلوبة <span className="required"></span></label>
                        <input type="number" id="nbrlicence" name="nbrlicence" required {...register("nbrlicence")} />
                    </div>

                    <div className="form-group">
                        <label>الاختصاصات</label>
                        {specialite.map((item, index) => (
                            <div className='row mb-2' key={index}>


                                <input
                                    className='col-1'
                                    type="checkbox"
                                    id={`parcour_${item.LibellerFR}`}
                                    name={`specialite[${index}]`}
                                    value={item}
                                    onChange={() => { changespeclialite(item.LibellerAR) }}
                                />
                                <img
                                    className='icon col-2'
                                    src={item.logo}
                                    alt="Club Logo"
                                    width="250px"
                                    height="250px"
                                />
                                <label className='col-6' htmlFor={`parcour_${item.LibellerFR}`}>{item.LibellerAR} </label>

                            </div>
                        ))}

                    </div>


                    <div className="form-button">
                        <button type="submit" className="submit-btn">تسجيل</button>
                    </div>
                </form>

                {popup && (



                    <div className="popup">
                        <div className="popup-container">
                            <p>تم إتمام الخطوة الأولى من التسجيل بنجاح لإكمال التسجيل وإنشاء حساب النادي يرجى اختيار كلمة المرور</p>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="password">كلمة السر:</label>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value) }}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="confirmPassword">تأكيد كلمة السر</label>
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                    />
                                </div>
                                <div>
                                    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>{message ? "كلمة المرور غير مطابقة" : null}</span>

                                </div>
                                <button onClick={(e) => { createcompte(e) }}>حفظ</button>
                            </form>
                        </div>
                    </div>
                )}

                {popupvalide && (



                    <div className="popup">
                        <div className="popup-container">
                            <p>
                                تهانينا، أصبح لدى ناديكم الآن حساب لدى الجامعة التونسية للجمباز. تأكد من تسجيل الدخول إلى حسابك باستخدام  رمز تسجيل والمعرف الوحيد:
                                <br />
                                <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>{idunique_id}</span>
                                {"  "}
                                لتنفيذ كافة الخطوات اللازمة لإتمام ملف التسجيل الخاص بك</p>
                            <button onClick={() => { navigate('/home') }}>صفحة تسجيل الدخول</button>
                        </div>

                    </div>
                )}
                {popupinvalide && (



                    <div className="popup">
                        <div className="popup-container">
                        <p>لديك حساب بالفعل يمكنك تسجيل الدخول إلى هذا الحساب عن طريق رمز تسجيل          </p>
                        <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>{idunique_id}</span>

                              
                            <button onClick={() => { setidunique('') ;setpopupinvalide(false) }}>  غلق</button>
                        </div>

                    </div>
                )}
            </div>

        </div>
    );
}

/**
 *          <button onClick={navigate('/login')}>Login</button>
<form onSubmit={handleSubmit}>
             <div>
                 <label htmlFor="password"> كلمة المرور</label>
                 <input
                 type="password"
                 id="password"
                 value={login.idunique}
                 onChange={(e)=>{setlogin({...login , idunique: e.target.value})}}
                 />
             </div>
             <div>
                 <label htmlFor="confirmPassword"> كلمة السر</label>
                 <input
                 type="password"
                 id="confirmPassword"
                 value={login.password}
                 onChange={(e)=>{setlogin({...login , password :  e.target.value})}}
                 />
             </div>
             <div>
             {message ? "كلمة سر خاطئة" : null }
             </div>
             <button onClick={(e)=>{createcompte(e)}}>Confirmer</button>
             </form> */